/* Type */

$sans-serif: "Inter", sans-serif;

/* Colours */

$background: white;

$color: #1e243c;
$color-light: #798c9c;

$color-purple-dark: #5843be;
$color-purple-light: #f3eeff;

$color-green-dark: #33c54c;
$color-green-light: #e5faf3;

$color-blue-dark: #1e70d8;
$color-blue-light: #e5f1ff;

$color-pink-dark: #ff247c;
$color-pink-light: #ffeef5;

$color-yellow-dark: #ffab02;
$color-yellow-light: #fff6e5;

/* Spacings */

$measure: 2em;

/* Responsive */

$breakpoint-small: 46em;
