.features {
  @extend .spacing-bottom;

  .feature-image {
    text-align: center;
  }

  .feature-group {
    margin: $measure 0;
    position: relative;
    align-items: center;

    img {
      width: 100%;
      max-width: 360px;
      height: auto;
      display: inline-block;
    }
  }

  .feature-content {
    @media screen and (max-width: $breakpoint-small) {
      margin: $measure * 2 0;
    }
  }

  ul {
    @extend .plain-ul;
    text-align: left;
  }

  li {
    padding-left: $measure;
    position: relative;
    line-height: 1.5em;
    margin-bottom: 0.5em;
    font-size: 1.125em;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: $color-light;
  }

  h2 {
    margin-top: 0;
  }

  .mini {
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;
    display: inline-block;
    padding: 0.5em 1em 0.5em 0.5em;
    border-radius: 8px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
    }
  }

  .game {
    .mini {
      color: $color-green-dark;
    }
    .mini {
      background: $color-green-light;
    }
  }

  .decklist {
    .mini {
      color: $color-blue-dark;
    }
    .mini {
      background: $color-blue-light;
    }
  }

  .home {
    .mini {
      color: $color-purple-dark;
    }
    .mini {
      background: $color-purple-light;
    }
  }
}
