.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.width {
  max-width: 50em;
  margin: 0 auto;
  padding-left: $measure * 2;
  padding-right: $measure * 2;

  @media screen and (max-width: $breakpoint-small) {
    padding-left: $measure;
    padding-right: $measure;
  }
}

.plain-ul {
  @extend .clearfix;
  list-style: none;
  padding: 0;
}

.spacing-bottom {
  margin-bottom: $measure * 3;

  @media screen and (max-width: $breakpoint-small) {
    margin-bottom: $measure * 2;
  }
}

.spacing-top {
  margin-top: $measure * 3;

  @media screen and (max-width: $breakpoint-small) {
    margin-top: $measure * 2;
  }
}
