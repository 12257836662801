h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  color: blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
