.support {
  @extend .spacing-top;
  margin-bottom: $measure;
  position: relative;
  padding: $measure;
  padding-left: $measure * 4;
  border-radius: $measure / 2;

  @media screen and (max-width: $breakpoint-small) {
    padding: $measure;
    text-align: center;
  }

  p {
    margin: 0;

    @media screen and (max-width: $breakpoint-small) {
      text-align: left;
    }
  }

  svg {
    height: $measure * 2;
    width: $measure * 2;
    position: absolute;
    left: $measure;
    top: 50%;
    margin-top: -$measure;

    @media screen and (max-width: $breakpoint-small) {
      position: relative;
      top: initial;
      margin-top: initial;
      left: initial;
    }
  }
}

.patreon {
  background: $color-purple-light;
  color: $color-purple-dark;

  svg {
    fill: $color-purple-dark;
  }
}
