footer {
  @extend .width;
  @extend .clearfix;
  padding-bottom: $measure;

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }

  ul {
    @extend .plain-ul;
  }

  li {
    float: left;
    margin-right: $measure / 2;

    @media screen and (max-width: $breakpoint-small) {
      float: initial;
    }
  }

  p {
    font-size: 0.75em;
    line-height: 1.5em;
    color: $color-light;
  }
}
