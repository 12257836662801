.download {
  @extend .clearfix;
  @extend .spacing-bottom;
  padding: 1em 0;

  a,
  span {
    display: inline-block;
  }

  a {
    @media screen and (max-width: $breakpoint-small) {
      display: block;
      margin-bottom: 1em;
    }
  }

  svg {
    vertical-align: middle;
  }

  .google-play {
    svg {
      opacity: 0.4;
    }
  }

  small {
    display: block;
    margin-top: 1em;
  }
}
