.grid-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr $measure 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-column-gap: $measure;
  -moz-column-gap: $measure;
  column-gap: $measure;

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }
}
