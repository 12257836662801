.hero {
  @extend .spacing-bottom;

  h1 {
    line-height: 1em;
    font-size: 2.5em;
    font-weight: 800;
  }

  h2 {
    line-height: 1em;
    font-size: 2em;
    font-weight: 800;
  }

  h1,
  h2 {
    @media screen and (max-width: $breakpoint-small) {
      font-size: 2em;
      line-height: 1.25em;
    }
  }

  p {
    font-size: 1.5em;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 1.25em;
    }
  }

  .rainbow {
    padding: 0.125em;
    border-radius: 8px;
    background: linear-gradient(90deg, #e5faf3 0%, #e5f1ff 50%, #f3eeff 100%);
  }
}
