@mixin text-gradient($from, $to) {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($from),
    to($to)
  );
  background: -o-gradient(left top, right top, from($from), to($to));
  background: linear-gradient(90deg, $from, $to);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonials {
  @extend .spacing-bottom;

  blockquote {
    padding: 0;
    margin: 0 0 $measure * 2 0;

    &.last {
      margin: 0;
    }

    p {
      font-size: 1.5em;
      margin-bottom: 0;
      margin-bottom: 1em;
      @include text-gradient($color-yellow-dark, $color-pink-dark);

      @media screen and (max-width: $breakpoint-small) {
        font-size: 1.125em;
      }
    }

    cite a {
      color: $color;
    }
  }

  div {
    margin-top: $measure;
    padding: $measure / 2;
    border-radius: $measure / 2;
    background: rgba($color-light, 0.1);

    p {
      margin: 0;
    }
  }
}
