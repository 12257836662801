header.global-header {
  @extend .clearfix;
  @extend .spacing-bottom;
  @extend .width;
  padding-top: 1em;
  padding-bottom: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .icon {
    width: 40px;
    height: 40px;

    @media screen and (max-width: $breakpoint-small) {
      width: 32px;
      height: 32px;
    }
  }

  h1 {
    font-size: 1.5em;
    margin: 0 0 0 0.5em;
    line-height: 40px;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 1em;
    }

    a {
      text-decoration: none;
      color: $color;
    }
  }

  .posts {
    margin-left: auto;
    padding: 0.5em 0.75em;
    border-radius: 8px;
    border: 1px solid $color-purple-dark;
    color: $color-purple-dark;

    &:hover {
      text-decoration: none;
      background: $color-purple-light;
    }
  }
}

header.post-header {
  margin-bottom: $measure;

  h1 {
    margin-bottom: 0;
  }
}
