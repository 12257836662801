html {
  overflow-x: hidden;
}

body {
  background-color: $background;
  color: $color;
  font-weight: normal;
  font-family: $sans-serif;
  line-height: 1.5;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

main {
  @extend .width;
  @extend .clearfix;
}
