.time {
  margin-top: 0;
  color: $color-light;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  svg {
    margin-right: 0.5em;
  }
}

.excerpt {
  margin: $measure * 2 0;

  h3 {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }

  .time {
    color: $color-light;
    margin-bottom: 1em;
  }
}
